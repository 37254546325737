import React from 'react';
import styled from 'styled-components';
import scalingSize from '$utils/scalingSize';
import { WrapMax } from '$components/Wraps';
import Mark from '$components/Mark';
import Heading1 from '$components/Heading1';
import Heading2 from '$components/Heading2';
import FadeInSection from '$components/FadeInSection';
import { Color } from '$utils/Color';
import Content from './Content';
import ProcessHeroButton from './ProcessHeroButton';
import { BlockProcessHeroProps } from './types';

const Outer = styled.section`
  ${scalingSize('margin-top', 40, 88)};
  ${scalingSize('margin-bottom', 32, 72)}
`;

const Inner = styled(WrapMax)`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em;

  @media screen and (min-width: 860px) {
    grid-gap: 1.75em;
  }
`;

const BlockProcessHero: React.FC<BlockProcessHeroProps> = ({
  h1,
  h2,
  h2Highlight,
  h2AfterHighlight,
  content,
  button,
}) => (
  <FadeInSection>
    <Outer>
      <Inner>
        <Heading1 process>{h1}</Heading1>
        <Heading2 process>
          {h2} <Mark color={Color.citrus4}>{h2Highlight}</Mark> {h2AfterHighlight}
        </Heading2>
        <Content>{content}</Content>
        {button && (
          <ProcessHeroButton href={button.URL} showArrow>
            {button.Title}
          </ProcessHeroButton>
        )}
      </Inner>
    </Outer>
  </FadeInSection>
);

export default BlockProcessHero;
