import React from 'react';
import styled from 'styled-components';
import scalingSize from '$utils/scalingSize';

const StyledContent = styled.p`
  line-height: 1.75;
  margin: 1em 0 2em;
  color: var(--color-body-text);
  ${scalingSize('font-size', 16, 20)}
  max-width: 40rem;

  @media screen and (min-width: 860px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1440px) {
    max-width: 50rem;
  }
`;

const Content: React.FC = ({ children }) => <StyledContent>{children}</StyledContent>;

export default Content;
