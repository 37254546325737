import React from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from '$components/Button';

const StyledButton = styled(Button)`
  margin: 1em 0 0;
  justify-self: start;
  max-width: fit-content;

  @media screen and (min-width: 860px) {
    justify-self: center;
    margin-top: 0;
    grid-column: 1 / 2;
  }
`;

const ProcessHeroButton: React.FC<ButtonProps> = ({ children, href, showArrow }) => (
  <StyledButton href={href} showArrow={showArrow}>
    {children}
  </StyledButton>
);

export default ProcessHeroButton;
